



































































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import appService from "@/services/appService";
import BlurredTenant from '@/components/BlurredTenant.vue';

export default Vue.extend({
  components: { BlurredTenant },
  data() {
    return {
      showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
      self: this,
      chargingStation: null,
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "startTime",
          "sort-desc": true,
          "class": "charging-sessions-table",
        },
        content: {
          name: "Plugged Sessions",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Organization",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("tenants", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              userId: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "User",
                  required: true,
                  "item-value": "_id",
                  "item-text": "email",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "email",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              retailerId: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Retailer",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("retailers", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              "chargingStationSnapshot.serialNumber": {
                attrs: {
                  label: "Serial Number",
                },
                rules: {
                  match: {},
                },
              },
              "chargingStationSnapshot.uniqueId": {
                attrs: {
                  label: "Charge Point ID",
                },
                rules: {
                  match: {},
                },
              },
              connectorId: {
                attrs: {
                  label: "Connector ID",
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          displayFields: {
            "details._id": {
              text: "Transaction ID",
              options: {
                subProp: "details._id",
                sortBy: "details._id",
              },
            },
            
            uniqueId: {
              text: "Charging Station",
              options: {
                subProp: "chargingStationSnapshot.uniqueId",
                sortBy: "chargingStationSnapshot.uniqueId",
              },
            },
            user: {
              text: "User Email",
              options: {
                subProp: "userSnapshot.email",
                label: true,
                sortBy: "userId",
              },
            },
            'user.phone': {
              text: "User Phone",
              options: {
                subProp: "userSnapshot.phone",
                label: true,
                sortBy: "userId",
              },
            },
            
            
            connectorId: {
              text: "Connector ID",
              sortable: true,
            },
            batteryFullAgo: {
              text: "Battery full ago (minutes)",
              sortable: true,
              options: {
                preTransform(value) {
                  return Math.floor((value || 0) / (60 * 1000));
                },
              },
            },
            startTime: {
              text: "Start Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            stopTime: {
              text: "End Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },

            retailer: {
              text: "Retailer",
              options: {
                subProp: "retailerSnapshot.name",
                label: true,
                sortBy: "retailerId",
              },
            },
            tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Organization",
              options: {
                subProp: "tenant.name",
                label: true,
                sortBy: "tenantId",
              },
            },
          },
          findAllOptionsFilter(options) {
            const activeTenant = appService.getActiveTenant();
            if (activeTenant) {
              options.filters.push({
                key: "tenantId",
                operator: "equal_to",
                value: activeTenant._id,
              });
            }

            const { $route } = this.context();
            if ($route.query.chargingStationId) {
              options.filters.push({
                key: "chargingStationId",
                operator: "equal_to",
                value: $route.query.chargingStationId,
              });
            }

            options.extraParams.currentTimeZoneOffset = new Date().getTimezoneOffset() * -1 * 60 * 1000;

            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("chargingSessions", "findSessionBatteryFullCharging", options);
              return [items, count];
            },
          },
        },
      },
      methods: {
      },
    };
  },
  async created() {
    const $route = this.$route;
    if ($route.query.chargingStationId) {
      const chargingStation = await coreApiClient.call("chargingStations", "findById", {
        id: $route.query.chargingStationId,
      });
      this.chargingStation = chargingStation;
    }
  },
});
